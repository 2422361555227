(function () {
  const fvCcontainer = document.querySelector('.header-container');
  const fvCcontainerHeight = fvCcontainer.clientHeight;
  const nav = document.querySelector('.nav-container');
  const header = document.querySelector('.header-container');
  const hamburgerMenu = document.querySelector('.hamburger-menu-container');
  const hamburgerMenuBorder = document.querySelector('.hamburger-menu__border');
  const hamburgerMenuBtnOpen = document.querySelectorAll('.gloval-nav__hamburger-menu-btn');
  const hamburgerMenuBtnClose = document.querySelector('.hamburger-menu__close-btn');
  window.addEventListener('scroll', () => {
    const scroll = window.scrollY;
    if (scroll > fvCcontainerHeight) {
      nav.classList.add('animation');
      header.style.display = 'none';
    }
  });
  for (let i = 0; i < hamburgerMenuBtnOpen.length; i++) {
    hamburgerMenuBtnOpen[i].addEventListener('click', (e) => {
      e.preventDefault();
      hamburgerMenu.classList.add('open');
      hamburgerMenu.setAttribute('aria-hidden', false);
    });
  }
  document.addEventListener('click', (e) => {
    e.stopPropagation();
    const target = e.target.className;
    if ([hamburgerMenuBtnClose.className, hamburgerMenu.className, hamburgerMenuBorder.className].includes(target)) {
      e.preventDefault();
      hamburgerMenu.classList.remove('open');
      hamburgerMenu.setAttribute('aria-hidden', true);
    }
  });
})();
